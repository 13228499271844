<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <h1>Check logs</h1>
            </div>
            <CheckDetailModal ref="checkDetailModal" />
            <div class="filter-bar-container">
                <div class="filter-bar">
                    <div>
                        <button type="button" class="btn btn-secondary" @click="toggleFilter">
                            <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                                Filter
                            <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <transition name="collapse">
                    <ChecksFilter
                        ref="checksFilter"
                        :checks="data"
                        @onFiltersApply="onFiltersApply"
                    />
                </transition>
            </div>
             <div class="table-bar">
                 <ChecksTable :checks="data" @openModal="getDetail" />
             </div>
        </div>
    </div>
</template>
<script>
import ChecksTable from '../../components/checkslogsView/ChecksTable'
import ChecksFilter from '../../components/checkslogsView/ChecksFilter'
import CheckDetailModal from '../../components/checkslogsView/CheckDetailModal'
import SessionStorage from '../../helpers/modules/storage/SessionStorage'
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            data: [],
            activeFilters: {
                email: "bart@billink.nl"
            },
            token_checks: ""
        }
    },
    methods: {
         getLogs() {
            let params = {
                limit: 10,
                page: 1,
                filter: this.activeFilters
            }
            checkAxios.post('/billink/log', params)
                .then(({ data }) => {
                    this.data = data
                }).catch(err => console.error(err))
            sessionStorage.setItem('checksFilterParams', JSON.stringify(params))    
        },
        toggleFilter() {
            this.$refs.checksFilter.toggle()
        },
        onFiltersApply(data) {
            this.activeFilters = data
            this.getLogs()
        },
        openModalDetail(data) {
            this.$refs.checkDetailModal.open(data)
        },
        async getDetail(id) {
            checkAxios.get(`/billink/log/${id}`)
                .then(({ data }) => {
                    this.openModalDetail(data)
                }).catch(err => console.error(err))
        }
    },
     mounted() {
        let checksFilters = SessionStorage.get('checksFilterParams')

        if (!!checksFilters) {
            if (Object.keys(checksFilters.filter).length) {
                this.activeFilters = checksFilters.filter
                this.$refs.checksFilter.fillFilterOptions(this.activeFilters)
            }
            this.getLogs()
        }
        this.getLogs()
    },
    components: {
        ChecksTable,
        ChecksFilter,
        CheckDetailModal,
        ChecksFilter
    },
    computed: {
        ...mapGetters({
            token: 'getBillinkToken'
        })
    }
}
</script>