<template>
    <b-modal v-model="showModal"
             size="lg"
             hide-footer="true"
             title="Detail response"
             header-class="card-header pause">
        <div class="row">
            <div class="col-md-12" style="display: flex">
                <div>
                    <pre><code>{{ data }}</code></pre>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            data: {}
        }
    },
    methods: {
        close () {
            this.showModal = false
        },
        open(data) {
            this.data = data
            this.showModal = true
        },
    }
}
</script>