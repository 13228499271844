<template>
    <table class="table">
        <thead>
            <tr>
                <th class="text-center">
                    <h6>Date</h6>
                </th>
                <th class="text-center">
                    <h6>Decision</h6>
                </th>
                <th class="table-res text-center">
                    <h6>Reason</h6>
                </th>
                <th class="text-left">
                    <h6>Details</h6>
                </th>    
            </tr>
        </thead>
        <tfoot v-if="!check" class="tfoot-no-check">
        </tfoot>
        <tbody>
        <tr v-for="item in checks" :key="item.id">
            <td class="text-center">{{ onDateFormat(item.created_at) }}</td>
            <td :class="!!item.decision ? 'check-true' : 'check-false'" class="text-center">
                <i class="fas fa-minus red" v-if="!item.decision"></i>
                <i class="fas fa-check-circle green" v-else></i>
            </td>
            <td class="text-center">{{ item.reason }}</td>
            <td><span class="cursor" @click="openModal(item.id)"><img src="../../../public//img/icons/eye-solid.svg" width="45" /></span></td>
        </tr>
        </tbody>
    </table>    
</template>
<script>
import moment from 'moment';
export default {
    props: {
        checks: {
            type: Array
        }
    },
    data() {
        return {}
    },
    methods: {
        onDateFormat(val) {
            return moment(val).format('DD-MM-YYYY')
        },
        openModal(id) {
            this.$emit("openModal", id)
        }
    }
}
</script>

<style scoped lang="scss">
    .red { 
        color: red; 
        font-size: 25px;    
    }
    .green { 
        color: green;
        font-size: 25px;
    }
    .check-false {
        background: #D9A5A5;
    }
    .check-true {
        background: #A4DC91;
    } ticket-21846
    .fa-stack-2x {
        border-radius: 50%;
        background: #c1a1c7;
    }
    .webshop-th {
        width: 100px;
    }
    .overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        &-inv {
            width: 160px;
        }
    }
    .name-td {
        div {
            width: 160px;
        }
    }
    .icon-edit {
        font-size: 20px;
        margin-top: 10px;
    }
    .icon-edit:hover {
        cursor: pointer;
    }
    table {
        tr {
            th {
                h6 {
                    font-size: 13px;
                    color: #fff;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .table {
            td, th {
                font-size: 10px;
                padding: 2px 6px;
                vertical-align: middle;
            }
            th {
                padding: 6px;
                &.openstaand,
                &.amount {
                    width: 70px;
                }
            }
            .overflow {
                width: 70px;
            }
            .tfoot-no-check td {
                text-align: center;
                &>* {
                    padding: 5px;
                    font-size: 11px;
                    margin: 0 20px;
                }
            }
        }
    }
    @media (max-width:1240px) {
        th i {
            display: none;
        }
        .cl .overflow {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    @media (max-width:480px) {
        .table {
            .cl .overflow {
                width: 80px;
            }
            td:last-child,
            .openstaand {
                border-right: 1px solid #003d5b !important;
            }
        }
    }
</style>