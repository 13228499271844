<template>
<div class="collapse show" id="filtercollapse" v-if="show">
        <div class="filter-content">
            <form @submit.prevent="applyFilters">
                <div class="form-row">
                    <div class="form-group col-md-8">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input2" class="col-form-label">Last name</label>
                                <input v-model="form.lastName" type="text" class="form-control" id="input2">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input3" class="col-form-label">House number</label>
                                <input v-model="form.houseNumber" type="text" class="form-control" id="input3">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input4" class="col-form-label">Post code</label>
                                <input v-model="form.postalCode" type="text" class="form-control" id="input4">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input6" class="col-form-label">Email</label>
                                <input v-model="form.email" type="email" style="font-size: 14px;" class="form-control" id="input6">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input6" class="col-form-label">COC number</label>
                                <input v-model="form.cocNumber" type="text" class="form-control" id="input6">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input7" class="col-form-label">Customer ID</label>
                                <input v-model="form.checkUuid" type="text" class="form-control" id="input7">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <a class="btn btn-secondary" style="margin-right: 5px;" @click.prevent="clearFilters">Clear</a>
                    <button type="submit" class="btn">Apply Filter</button>
                </div>
            </form>
        </div>
    </div>    
</template>
<script>
export default {
    props: {
		activeLanguage: {
			type: Object,
			required: true
		},
	},
    data() {
        return {
            show: false,
            form: {},
        }
    },
    methods: {
            clearFilters() {
                this.form = {}
                this.$emit('onFiltersApply', {})
            },
            toggle() {
                this.show = !this.show
            },
            applyFilters() {
                this.$emit('onFiltersApply', this.form)
            },
            fillFilterOptions(data) {
                this.form = data
                this.show = true
            }
        }
}
</script>